<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <h1>{{ this.$route.params.eventName }}</h1>
  </main>
</template>

<script>
// import axios from 'axios';
// import { response } from 'express';

export default {
  data() {
    var name = this.$route.params.eventName;
    console.log(name);
    window.location.href = `https://www.tickpick.com/search?q=${name}`;
  }
};
</script>
